<template>
  <LayoutFilter :toggleCollapseEnable="false" :onFilter="ok" :onReset="resetForm" :collapseHeight="60">
    <el-form :model="filterForm" :rules="rules" ref="filterFilterForm" label-width="90px" size="medium"
      class="filter-from" @submit.native.prevent>
      <div class="filter-item">
        <!--        当一个 form 元素中只有一个输入框时，在该输入框中按下回车应提交该表单。
如果希望阻止这一默认行为，可以 在 标签上添加 @submit.native.prevent。-->
        <el-form-item label="过滤词内容：" prop="keyword">
          <el-input @keyup.enter.native="ok" v-model="form.keyword" placeholder="请输入内容"></el-input>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
export default {
  name: "ListFilter",
  components: { LayoutFilter },
  props: {
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      form: null,
    };
  },
  watch: {
    filterForm: {
      deep: true,
      handler(val) {
        this.form = val;
      },
    },
  },
  created() {
    this.form = this.filterForm;
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["filterFilterForm"].resetFields();
    },
    //筛选
    ok() {
      this.$emit("update", this.form);
    },
  },
};
</script>

<style scoped>
</style>

