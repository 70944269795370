<template>
  <el-dialog :visible="value" class="dialog-vertical" :title="(form.id ? '编辑':'新增') + '过滤词'" @close="close">
    <el-form v-if="value" size="medium" label-position="right" label-width="70px" @submit.native.prevent>
      <el-form-item label="标题：" prop="title">
        <el-input size="medium" placeholder="请输入标题" v-focus type="text" v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="内容：" prop="words">
        <el-input placeholder="用分号；分开过滤词" type="textarea" :rows="6" v-model="form.words"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="close">取消</el-button>
      <el-button size="small" type="primary" @click="confirm" :loading="loading">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { saveDetail } from "../api/filter-list";

export default {
  name: "AddFilterWords",
  props: {
    value: {
      type: Boolean,
    },
    item: {
      type: Object,
      default: {
        title: "",
        words: "",
      },
    },
  },
  data() {
    return {
      loading: false,
      form: {},
      rules: {},
    };
  },
  watch: {
    item: {
      handler(val) {
        this.form = val;
      },
      deep: true,
    },
  },
  created() {
    this.form = this.item;
  },
  methods: {
    confirm() {
      this.loading = true;
      saveDetail({
        ...this.form,
      })
        .then((res) => {
          this.loading = false;
          this.$message.success(res.msg);
          this.$emit("confirm");
          this.close();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dialog {
  .el-form {
    .el-form-item {
      .el-input {
        width: 90%;
      }

      ::v-deeptextarea {
        width: 90%;
      }
    }
  }
}
</style>
