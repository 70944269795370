<template>
  <div class="list-page">
    <div class="top-btn">
      <el-button size="small" type="primary" @click="addFilterWords">添加过滤词</el-button>
      <!--      <el-button v-if="filterForm.tab === 'deleted'" size="small" type="danger">删除</el-button>-->
      <!--      <el-button v-else size="small">删除</el-button>-->
    </div>
    <list-filter :filterForm="filterForm" @update="doFilter" />
    <list-tabs v-model="filterForm.tab" :tabs="tabArr" @tab-click="handleTabClick">
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn" v-if="filterForm.tab === 'normal'">
        <el-button type="danger" size="small" @click="toDelete()">删除</el-button>
      </div>
      <div class="list-btn" v-if="filterForm.tab === 'deleted'">
        <el-button type="success" size="small" @click="toPutBack()">还原</el-button>
        <el-button type="danger" size="small" @click="toDelete(0, true)">删除</el-button>
      </div>
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%;"
        @selection-change="handleSelectionChange">
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button-group v-if="filterForm.tab === 'deleted'">
              <el-button size="small" type="text" @click="toPutBack(scope.row.id)">还原</el-button>
              <el-button size="small" type="text" @click="toDelete(scope.row.id, true)">删除</el-button>
            </el-button-group>
            <el-button-group v-else>
              <el-button size="small" type="text" @click="editDetail(scope.row.id)">编辑</el-button>
              <el-button size="small" type="text" @click="toDelete(scope.row.id)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column :prop="th.prop" :label="th.label" :width="th.width || ''"
            :min-width="th.minWidth || ''" :key="index" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="th.prop === 'article.title'">{{scope.row.article.title | placeholder}}</span>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
      <add-filter-words :item="editObj" v-model="isShowAdd" @confirm="handleAddSuccess"></add-filter-words>
    </div>
  </div>
</template>

<script>
import {
  getDetail,
  getList,
  getTabsData,
  putBack,
  softDelete,
  trueDelete,
} from "../api/filter-list";

import init from "../mixins/list-init";
import filter from "../mixins/list-filter";
import tab from "../mixins/list-tabs";
import operation from "../mixins/list-operation";
import pagination from "../mixins/list-pagination";

import Pagination from "../../../base/components/Default/Pagination";
import ListFilter from "../components/ListFilter";
import AddFilterWords from "../components/AddFilterWords";

export default {
  mixins: [init, filter, tab, operation, pagination],
  components: {
    AddFilterWords,
    Pagination,
    ListFilter,
  },
  data() {
    return {
      loading: false,
      thead: [
        { label: "ID", prop: "id", minWidth: 80 },
        { label: "标题", prop: "title", minWidth: 120 },
        { label: "内容", prop: "words", minWidth: 200 },
        { label: "词汇数", prop: "words_count" },
      ],
      filterForm: {},
      pageData: {
        tab: "normal",
        page_size: 15,
      },
      // 筛选缓存
      filters: {
        normal: null,
        deleted: null,
      },
      editObj: {},
      isShowAdd: false,
    };
  },
  created() {},
  methods: {
    addFilterWords() {
      this.editObj = {
        id: 0,
        title: "",
        words: "",
      };
      this.isShowAdd = true;
    },
    handleAddSuccess() {
      this.getListData();
    },
    editDetail(e) {
      getDetail({
        id: e,
      })
        .then((res) => {
          this.editObj = res.data;
          this.isShowAdd = true;
        })
        .catch((err) => {});
    },
    /**
     * 由于每个列表的筛选条件不一，这个方法只能在 view 中定义
     * 用于初始化筛选条件，也作切换tab时还原筛选条件
     * @param {String} name   tab名称，不传时表示初始化
     */
    initFilterForm(name) {
      this.filterForm = Object.assign(
        {},
        {
          keyword: "", // 评论内容
          tab: name || "normal", // 当前筛选tab
          page_size: 15, // 每页多少条数据
        }
      );
    },
    // API
    doGetListData(params) {
      return getList(params);
    },
    doGetTabsData() {
      return getTabsData();
    },
    doSoftDelete(id) {
      return softDelete({
        id: id,
      });
    },
    doTrueDelete(id) {
      return trueDelete({
        id: id,
      });
    },
    doPutBack(id) {
      return putBack({
        id: id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list-page {
  ::v-deep.el-table .cell {
    /*overflow: hidden !important; !*设置超出的部分进行影藏*!*/
    /*text-overflow: ellipsis !important; !*设置超出部分使用省略号*!*/
    white-space: nowrap !important; /*设置为单行*/
  }
}
</style>
