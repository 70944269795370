/**
 * Vue mixin
 * 页码，按需引入
 */
import Pagination from "@/base/components/Default/Pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {

    }
  },
  methods: {
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = {...this.filterForm, ...e};
      this.getListData(pageData);
    },
  }
}
