/**
 * Vue mixin
 * 列表初始化相关
 */

export default {
  components: {},
  data() {
    return {
      loading: false,
      tbody: [],
    }
  },
  created() {
    // 在具体页面声明该方法，初始化筛选条件，未声明则筛选条件为空
    this.initFilterForm && this.initFilterForm();
    // 获取列表数据
    this.getListData();
  },
  methods: {
    getListData() {
      this.loading = true;
      // 调用具体页面声明的获取列表数据的方法
      this.doGetListData(this.filterForm).then((res) => {
        const {data} = res;
        this.tbody = data.data;
        this.pageData = data;
        this.filters[data.tab] = {...this.filterForm};
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
      });
    },
  }
}
