import api from "../../../base/utils/request";

export const getList = data => {
  return api({
    url: "/admin/admin/filter_word/index",
    method: "post",
    data
  })
}

export const getTabsData = data => {
  return api({
    url: '/admin/admin/filter_word/tabs',
    method: 'post',
    data
  })
}

export const saveDetail = data => {
  return api({
    url: '/admin/admin/filter_word/save',
    method: 'post',
    data
  })
}

export const getDetail = data => {
  return api({
    url: '/admin/admin/filter_word/detail',
    method: 'post',
    data
  })
}

export const softDelete = data => {
  return api({
    url: '/admin/admin/filter_word/softDelete',
    method: 'post',
    data
  })
}

export const trueDelete = data => {
  return api({
    url: '/admin/admin/filter_word/del',
    method: 'post',
    data
  })
}

export const putBack = data => {
  return api({
    url: '/admin/admin/filter_word/putBack',
    method: 'post',
    data
  })
}
